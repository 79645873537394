<template>
  <div>
    <!-- 动态活码 -->
    <div class="fullscreen-dynamic-qrcode">
      <div class="pop-musk"></div>
      <div id="dynamic_qrcode_img" ref="dynamic_qrcode_img" title=""></div>
    </div>

  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import CryptoJS from 'crypto-js'

export default {
  components: {
  },
  data() {
    return {
      activeId: '',
      title: '',

      dynamicQRCodeTimer: null,
    }
  },
  created() {

  },
  mounted() {
    this.activeId = this.$route.query.evt_id
    if(this.$route.query.name){
      document.title = decodeURIComponent(this.$route.query.name)
    }

    this.clearDynamicQRCodeTimer()
    this.refreshDynamicQRCode()
    this.dynamicQRCodeTimer = setInterval(this.refreshDynamicQRCode, 3000)
  },
  beforeDestroy(){
    this.clearDynamicQRCodeTimer()
  },
  destroyed() {

  },
  methods: {
    refreshDynamicQRCode(){
      this.$refs.dynamic_qrcode_img.innerHTML = ''
      this.$nextTick(() => {
        const currentTime = new Date().getTime()
        const timeCode = CryptoJS.SHA256(currentTime + "-" + this.activeId).toString().slice(-10)
        const d = 500
        const qrdiv = document.getElementById('dynamic_qrcode_img')
        new QRCode(qrdiv, {
          width:  d,  // 二维码宽度 
          height: d, // 二维码高度
          text: window.location.origin + '/#/activity/couponevent?did='+this.activeId+"&t="+currentTime+"&tcode="+timeCode,
          correctLevel: QRCode.CorrectLevel.L
        })
      })
    },
    clearDynamicQRCodeTimer(){
      if(this.dynamicQRCodeTimer){
        clearInterval(this.dynamicQRCodeTimer)
        this.dynamicQRCodeTimer = null
      }
    },
  }
}
</script>

<style lang="scss">


.fullscreen-dynamic-qrcode .close{
  position: fixed;
  right: 0;
  top: 0;
  width: 6vh;
  height: 6vh;
  z-index: 99;
  padding: 1vh;
}
.fullscreen-dynamic-qrcode .close img{
  width: 100%;
  height: 100%;
}
.fullscreen-dynamic-qrcode .pop-musk{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  top: 0;
  left: 0;
  z-index: 99;
}
#dynamic_qrcode_img{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
#dynamic_qrcode_img canvas,
#dynamic_qrcode_img img {
  width: 50vh;
  height: 50vh;
}


</style>